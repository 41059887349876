export const environment = {
  production: true,
  api: 'https://api.eauth.libresolucoes.com.br',
  clientId: 1,
  clientSecret: 'zjF1a1lneaJjvGB9vnrlNE2JAObvx9pUcSb1twSn',
  recaptchaSiteKey: '6LcRIs4qAAAAAOdu2MeujVgNLrm78exSSf2WQQEc',
  eStorageUrl: 'https://estorage.libresolucoes.com.br',
  eStorageId: '1',
  eStorageSecret: 'sF4jiWhcOMhHrkHKitDvcJdFJM45CGhayDL2Vb1g'
};
